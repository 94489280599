import { useTranslation } from "react-i18next";
import { useTheme } from "~/utils/theme-provider";
import { DialogWindow } from "~/utils/api.interfaces.enums";

declare let window: DialogWindow;
export function ModalBoostLevelRights({}) {
  const { t } = useTranslation("translation");
  const [theme] = useTheme();

  return (
    <>
      <dialog className="modal" data-theme={theme} id="modalBoostLevelRights">
        <div className="modal-box">
          <div className="text-primary m-auto flex items-center justify-center my-6 dark:text-secondary w-full">
            <svg
              width={32}
              height={32}
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width={32} height={32} rx={16} fill="#974DDE" />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.173 9.125l-6.846 6.884h3.515c.715 0 1.246.665 1.091 1.367l-1.18 5.344 7.05-7.733h-3.656c-.727 0-1.26-.686-1.084-1.396l1.11-4.466zm.898-3.79c.8-.806 2.148-.04 1.874 1.066l-1.627 6.544h4.562c.973 0 1.481 1.162.824 1.882L12.943 26.631c-.773.847-2.163.12-1.915-1.003l1.675-7.577H8.12c-.996 0-1.494-1.21-.79-1.917l10.74-10.8z"
                fill="#fff"
              />
            </svg>
          </div>
          <h3 className="text-2xl font-bold mb-2 text-center">
            {t("titleModalBoostLevelRights")}
          </h3>
          <p className="text-center ">{t("bodyModalBoostLevelRights")}</p>
          <p className="text-center ">
            <a
              href="https://mingle.sport/team-boost/how/"
              target="_blank"
              className="btn btn-link btn-secondary dark:text-secondary no-underline"
            >
              {t("linkLabelBoostLevelRights")}
            </a>
          </p>
          <div className="modal-action">
            <div>
              <label
                className="btn btn-primary btn-outline btn-block"
                onClick={(e) => {
                  e.preventDefault();
                  window.modalBoostLevelRights.close();
                }}
              >
                {t("schedulePages.modalDeleteLabelCancel")}
              </label>
            </div>
            <div>
              <a
                href="https://mingle.sport/team-boost/how/"
                className="btn btn-primary w-full"
              >
                Boost my team
              </a>
            </div>
          </div>
        </div>
        <form
          method="dialog"
          className="modal-backdrop fixed left-0 top-0 w-screen h-screen backdrop-blur-sm"
        >
          <button>close</button>
        </form>
      </dialog>
    </>
  );
}
